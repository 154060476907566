import { default as environmentt } from "../config/environment.json"; // IL FAUT FAIRE CECI INSTEAD, GOOD ?
// IL FAUT FAIRE CECI INSTEAD, GOOD ?
/* trj pas lol jonathan va essayer de relancer */
const configForDevelopment = {
  providers: {
    google: {
      name: "google",
      url: "/auth/google",
      authorizationEndpoint: "https://accounts.google.com/o/oauth2/v2/auth",
      /* redirectUri: environmentt.front_front + "/home", */
      redirectUri: environmentt.homepage +  "/home",
      scope: ["openid", "profile", "email"],
      scopePrefix: "openid",
      scopeDelimiter: " ",
      requiredUrlParams: ["scope"],
      optionalUrlParams: ["display"],
      display: "popup",
      response_type: "code",
      type: "2.0",
      clientId:"653214094148-0lb8ms2hq0mj63mt0pruh89l2th72i7t.apps.googleusercontent.com",
      popupOptions: { width: 452, height: 633 },
    },
    twitter: {
      name: "twitter",
      url: "/auth/x",
      // authorizationEndpoint: 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=d0lxZWNUSzhESjJDNTVWdXZIeUc6MTpjaQ&redirect_uri=http://localhost:8082&scope=users.read offline.access&state=state&code_challenge=challenge&code_challenge_method=plain' ,
      authorizationEndpoint: "https://x.com/i/oauth2/authorize",
      /*       redirectUri: `${environmentt.front_front}/auth/signin/intermediate`, */
      /* redirectUri: environmentt.front_front + "/home", */
      redirectUri: environmentt.homepage + "/home",
      clientId: "d0lxZWNUSzhESjJDNTVWdXZIeUc6MTpjaQ", //camelcase!
      type: "2.0",
      scopeDelimiter: " ",
      state: "state",
      responseType: "code",
      codeChallenge: "challenge" /* trj vide */, // ici CamelCase, why dontknow just owrks
      codeChallengeMethod: "plain", //ici en dessous pas camelCase
      defaultUrlParams: [
        "response_type",
        "client_id",
        "redirect_uri",
        "scope",
        "state",
        "code_challenge",
        "code_challenge_method",
      ],
      optionalUrlParams: ["state"],
      scope: "users.read offline.access tweet.read",
      popupOptions: { width: 495, height: 645 },
    },
  },
  // Our Node API is being served from localhost:5000
  baseUrl: environmentt.users_api, //backend
  // The API specifies that new users register at the POST /users enpoint.
  signupUrl: "auth/register", //backend
  signupRoute: "signup", //frontend
  signupRedirect: "home", //frontend
  // Logins happen at the POST /sessions/create endpoint.
  loginUrl: "auth/login", //backend
  loginPinUrl: "auth/loginFromPin",
  loginRoute: "login", //frontend
  loginPinRoute: "pin_code",
  loginRedirect: "home", //frontend
  // The API serves its tokens with a key of id_token which differs from
  // aureliauth's standard.
  tokenName: "token",
  // Once logged in, we want to redirect the user to the welcome view.
  httpInterceptor: true,
  loginOnSignup: true,
  logoutRedirect: "login",
  profileUrl: "profile/authservice",
  tokenRoot: false,
  tokenPrefix: "aurelia",
  responseTokenProp: "token",
  authHeader: "Authorization",
  authToken: "Bearer",
  withCredentials: true,
  platform: "browser",
  storage: "localStorage",
};
const configForProduction = {
  providers: {},
};

let config;

if (window.location.hostname === "localhost") {
  config = configForDevelopment;
} else {
  config = configForProduction;
}

config = configForDevelopment;

export default config;
